import { NgModule } from '@angular/core';

import { CarRoutingModule } from './car.routing';
import { CarDetailComponent } from './components/car-detail/car-detail.component';
import { CarComponent } from './car.component';
import { CoreModule } from 'src/app/core/core.module';
import { SwiperModule } from 'swiper/angular';

@NgModule({
  declarations: [CarDetailComponent, CarComponent],
  imports: [CarRoutingModule, CoreModule, SwiperModule],
})
export class CarModule {}
