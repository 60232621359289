import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SwiperModule } from 'swiper/angular';
import { MaterialModule } from '../core/material.module';
import { AutocompleteListComponent } from './components/autocomplete/autocomplete-list/autocomplete-list.component';
import { CarListCardComponent } from './components/car-list-card/car-list-card.component';
import { CarListComponent } from './components/car-list/car-list.component';
import { CheckboxListComponent } from './components/checkbox/checkbox-list/checkbox-list.component';
import { DialogCarConfirmComponent } from './components/dialog/dialog-car-confirm/dialog-car-confirm.component';
import { DialogCarFormComponent } from './components/dialog/dialog-car-form/dialog-car-form.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ImageToBase64Directive } from './directives/image-to-base64.directive';
import { MockImageDirective } from './directives/mock-image.directive';
import { ToBase64Pipe } from './pipes/to-base64.pipe';
import { ToFilePipe } from './pipes/to-file.pipe';
import { ToWatermarkPipe } from './pipes/to-watermark.pipe';

const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  CarListComponent,
  CarListCardComponent,
  LoaderComponent,
  NotFoundComponent,
  CheckboxListComponent,
  AutocompleteListComponent,
];

const DIALOGS_COMPONENTS = [DialogCarFormComponent, DialogCarConfirmComponent];

const DIRECTIVES = [MockImageDirective, ImageToBase64Directive];

const PIPES = [ToBase64Pipe, ToFilePipe, ToWatermarkPipe];

@NgModule({
  declarations: [...COMPONENTS, ...DIALOGS_COMPONENTS, ...DIRECTIVES, ...PIPES],
  exports: [...COMPONENTS, ...DIALOGS_COMPONENTS, ...DIRECTIVES, ...PIPES],
  entryComponents: [...DIALOGS_COMPONENTS],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MaterialModule,
    DragDropModule,
    FormsModule,
    SwiperModule,
    FontAwesomeModule,
  ],
})
export class SharedModule {}
