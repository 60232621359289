import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Car } from 'src/app/core/models/car';

@Component({
  selector: 'autoclean-car-list',
  templateUrl: './car-list.component.html',
  styleUrls: ['./car-list.component.scss'],
})
export class CarListComponent implements OnInit, OnChanges {
  @Input() title = '';
  @Input() cars: Car[] = [];

  @ViewChild('paginatorTop', { read: MatPaginator })
  paginatorTop: MatPaginator = {} as MatPaginator;
  @ViewChild('paginatorBottom', { read: MatPaginator })
  paginatorBottom: MatPaginator = {} as MatPaginator;

  pageSize = 9;
  dataSource: Car[] = [];
  activePageDataChunk: Car[] = [];
  currentPage = 0;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.dataSource = this.cars;

    if (this.router.url.includes('/car')) {
      this.route.params.pipe(take(1)).subscribe(async (params) => {
        this.currentPage = await (params['page'] != 0 ? params['page'] : 1);

        this.currentPage =
          this.dataSource.length / this.pageSize > this.currentPage
            ? this.currentPage >= this.dataSource.length % this.pageSize
              ? (this.dataSource.length % this.pageSize) - 1
              : this.currentPage
            : 1;

        await this.router.navigate(['/car', this.currentPage]);

        if (this.dataSource.length > this.pageSize) {
          this.paginatorTop.pageIndex = this.currentPage - 1;
          this.paginatorBottom.pageIndex = this.currentPage - 1;
          this.paginatorTop.pageSize = this.pageSize;
          this.paginatorBottom.pageSize = this.pageSize;
        }

        const firstCut = (this.currentPage - 1) * this.pageSize;
        const secondCut = firstCut + this.pageSize;

        this.activePageDataChunk = this.dataSource.slice(firstCut, secondCut);
      });
    } else {
      this.activePageDataChunk = this.dataSource.slice(0, this.pageSize);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cars) {
      this.dataSource = changes.cars.currentValue;
      this.activePageDataChunk = this.dataSource.slice(0, this.pageSize);
    }
  }

  onPageChanged(pageEvent: PageEvent): void {
    this.paginatorTop.pageIndex = pageEvent.pageIndex;
    this.paginatorTop.pageSize = pageEvent.pageSize;
    this.paginatorBottom.pageIndex = pageEvent.pageIndex;
    this.paginatorBottom.pageSize = pageEvent.pageSize;

    this.router.navigate(['/car', pageEvent.pageIndex + 1]);

    const firstCut = pageEvent.pageIndex * pageEvent.pageSize;
    const secondCut = firstCut + pageEvent.pageSize;
    this.activePageDataChunk = this.dataSource.slice(firstCut, secondCut);
  }
}
