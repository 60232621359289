export const lastsoldOut = [
  {
    name: 'Car 1',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic02.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: true,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 2',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic03.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: true,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 3',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic04.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: true,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 4',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic05.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: true,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 5',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic06.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: true,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 6',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic07.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: true,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
];
export const lastNewCars = [
  {
    name: 'Car 1',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic02.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: false,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 2',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic03.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: false,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 3',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic04.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: false,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 4',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic05.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: false,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 5',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic06.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: false,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 6',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic07.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: false,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
];

export const carList = [
  {
    name: 'Car 1',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic02.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: true,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 2',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic03.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: true,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 3',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic04.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: true,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 4',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic05.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: true,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 5',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic06.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: true,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 6',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic07.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: true,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 1',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic02.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: false,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 2',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic03.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: false,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 3',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic04.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: false,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 4',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic05.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: false,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 5',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic06.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: false,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 6',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic07.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: false,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },

  {
    name: 'Car 1',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic02.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: true,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 2',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic03.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: true,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 3',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic04.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: true,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 4',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic05.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: true,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 5',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic06.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: true,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 6',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic07.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: true,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 1',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic02.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: false,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 2',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic03.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: false,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 3',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic04.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: false,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 4',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic05.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: false,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 5',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic06.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: false,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    name: 'Car 6',
    brand: 'Mercedes',
    model: 'Classe C',
    color: 'gris',
    price: 200000,
    image: ['assets/images/pic07.jpg'],
    date: new Date(),
    specification: 'IV COUPE 43 AMG 4MATIC 9G-TRONIC',
    year: 2010,
    km: 100000,
    pw: 20,
    soldOut: false,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
];
