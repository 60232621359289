<mat-form-field fxFill appearance="standard">
  <mat-label>{{ name }}</mat-label>
  <input
    type="text"
    placeholder="Pick one"
    aria-label="Number"
    matInput
    [formControlName]="formControlName"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
