import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Car } from 'src/app/core/models/car';
import { CarService } from 'src/app/core/services/car/car.service';
import { UserService } from 'src/app/core/services/user/user.service';
import Swiper, { Navigation, SwiperOptions } from 'swiper';
import { DialogCarConfirmComponent } from '../dialog/dialog-car-confirm/dialog-car-confirm.component';
import { DialogCarFormComponent } from '../dialog/dialog-car-form/dialog-car-form.component';

Swiper.use([Navigation]);

@Component({
  selector: 'autoclean-car-list-card',
  templateUrl: './car-list-card.component.html',
  styleUrls: ['./car-list-card.component.scss'],
})
export class CarListCardComponent implements OnInit {
  @Input() listOfCars: Car[] = [];

  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 50,
    navigation: true,
    autoHeight: true,
    lazy: true,
  };

  isAuthenticated = '';

  constructor(
    private userService: UserService,
    private carService: CarService,
    private dialog: MatDialog
  ) {
    this.userService.currentAuthStatus.subscribe((authStatus) => {
      this.isAuthenticated = authStatus;
    });
  }

  ngOnInit(): void {
    // this.listOfCars
  }

  update(car: Car): void {
    const dialogRef = this.dialog.open(DialogCarFormComponent, {
      data: car,
      width: '100%',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.carService.updateCar(result).then(async () => {
          const carIndex = await this.listOfCars.findIndex(
            (flt) => flt.id === result.id
          );
          this.listOfCars[carIndex] = result;
        });
      }
    });
  }

  toggleCarSoldOut(car: Car): void {
    const dialogRef = this.dialog.open(DialogCarConfirmComponent, {
      data: {
        title: car.isSoldout ? 'Vente' : 'Vendu',
        message: car.isSoldout
          ? 'Ce véhicule est remis en vente ?'
          : 'Ce véhicule est vendu ?',
        confirm: 'Oui',
        cancel: 'Non',
      },
      width: '400px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        if (car.id) {
          car.isSoldout = !car.isSoldout;
          this.carService.updateCar(car);
        }
      }
    });
  }

  deleteCar(car: Car): void {
    const dialogRef = this.dialog.open(DialogCarConfirmComponent, {
      data: {
        title: 'Suppression',
        message: 'Voulez-vous vraiment supprimer ce véhicule ?',
        confirm: 'Supprimer',
        cancel: 'Annuler',
      },
      width: '400px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        if (car.id) {
          this.carService.deleteCar(car).then(async () => {
            this.listOfCars = await this.listOfCars.filter(
              (flt) => flt.id !== car.id
            );
          });
        }
      }
    });
  }
}
