import { Pipe, PipeTransform } from '@angular/core';
import * as watermark from 'watermarkjs';

@Pipe({
  name: 'toWatermark',
})
export class ToWatermarkPipe implements PipeTransform {
  transform(value: File): any {
    this.fileToWatermark(value).then((watermark) => {
      console.log('watermark', watermark);
      return watermark;
    });
  }

  fileToWatermark(file: File): Promise<string> {
    return new Promise((resolve) => {
      watermark([file, '/assets/images/autoclean-watermark.png'])
        .image(watermark.image.lowerRight(0.5))
        .then((watermark: any) => {
          if (watermark.src) {
            const watermarkFile = watermark.src;
            resolve(watermarkFile);
          }
        });
    });
  }
}
