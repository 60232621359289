import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsCarIdentityGuard } from 'src/app/core/guards/is-car-identity.guard';
import { CarDetailResolver } from 'src/app/core/resolvers/car-detail-resolver.resolver';
import { CarComponent } from './car.component';
import { CarDetailComponent } from './components/car-detail/car-detail.component';

const routes: Routes = [
  { path: '', component: CarComponent },
  { path: ':page', component: CarComponent },
  {
    path: ':id/detail',
    component: CarDetailComponent,
    canActivate: [IsCarIdentityGuard],
    resolve: { car: CarDetailResolver },
  },
  { path: ':id', redirectTo: ':id/detail', pathMatch: 'full' },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CarRoutingModule {}
