<section id="main">
  <div class="container">
    <div fxLayout="column" fxLayoutGap="3rem">
      <autoclean-car-list
        [title]="'Nos derniers véhicules'"
        [cars]="lastNewCars"
      ></autoclean-car-list>

      <autoclean-car-list
        [title]="'Nos dernières ventes'"
        [cars]="lastSoldOut"
      ></autoclean-car-list>

      <div fxLayout="row" fxLayoutAlign="center center" fxFlex="100">
        <a
          href="/car"
          class="button large"
          (click)="
            lastNewCars?.length === 0 && lastSoldOut?.length === 0
              ? $event.preventDefault()
              : $event.stopPropagation()
          "
          [ngClass]="{
            disabled: lastNewCars?.length === 0 && lastSoldOut?.length === 0
          }"
          >Voir toutes nos ventes</a
        >
      </div>
    </div>
  </div>
</section>
