import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toBase64',
})
export class ToBase64Pipe implements PipeTransform {
  transform(value: File): any {
    return this.fileToBase64(value).then((base64: string) => {
      return base64;
    });
  }

  public fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }
}
