import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Car } from 'src/app/core/models/car';
import { CarService } from 'src/app/core/services/car/car.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { DialogCarConfirmComponent } from 'src/app/shared/components/dialog/dialog-car-confirm/dialog-car-confirm.component';
import { DialogCarFormComponent } from 'src/app/shared/components/dialog/dialog-car-form/dialog-car-form.component';
import Swiper, { Navigation, Pagination, SwiperOptions } from 'swiper';

Swiper.use([Pagination, Navigation]);

@Component({
  selector: 'autoclean-car-detail',
  templateUrl: './car-detail.component.html',
  styleUrls: ['./car-detail.component.scss'],
})
export class CarDetailComponent {
  car: Car;

  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 50,
    navigation: true,
    autoHeight: true,
    pagination: {
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + '</span>';
      },
    },
  };

  isAuthenticated = '';

  constructor(
    private userService: UserService,
    public route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private carService: CarService
  ) {
    this.userService.currentAuthStatus.subscribe((authStatus) => {
      this.isAuthenticated = authStatus;
    });

    this.car = route.snapshot.data.car;
  }

  print(): void {
    window.print();
  }

  update(car: Car): void {
    const dialogRef = this.dialog.open(DialogCarFormComponent, {
      data: car,
      width: '100%',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.carService.updateCar(result).then(async () => {
          this.car = await result;
        });
      }
    });
  }

  toggleCarSoldOut(car: Car): void {
    const dialogRef = this.dialog.open(DialogCarConfirmComponent, {
      data: {
        title: car.isSoldout ? 'Vente' : 'Vendu',
        message: car.isSoldout
          ? 'Ce véhicule est remis en vente ?'
          : 'Ce véhicule est vendu ?',
        confirm: 'Oui',
        cancel: 'Non',
      },
      width: '400px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        if (car.id) {
          car.isSoldout = !car.isSoldout;
          this.carService.updateCar(car);
        }
      }
    });
  }

  deleteCar(car: Car): void {
    const dialogRef = this.dialog.open(DialogCarConfirmComponent, {
      data: {
        title: 'Suppression',
        message: 'Voulez-vous vraiment supprimer ce véhicule ?',
        confirm: 'Supprimer',
        cancel: 'Annuler',
      },
      width: '400px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        if (car.id) {
          this.carService.deleteCar(car).then(async () => {
            await this.router.navigate(['/car']);
          });
        }
      }
    });
  }
}
