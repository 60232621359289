import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'autoclean-dialog-car-confirm',
  templateUrl: './dialog-car-confirm.component.html',
  styleUrls: ['./dialog-car-confirm.component.scss'],
})
export class DialogCarConfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogCarConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
