<div
  fxLayout="row"
  fxLayoutAlign="center center"
  fxFill
  *ngIf="timer; else elseNoData"
>
  <mat-spinner></mat-spinner>
</div>

<ng-template #elseNoData>
  <div fxLayout="row" fxLayoutAlign="center center" fxFill>
    <div fxLayout="column" fxLayoutAlign="center center" fxFill>
      <mat-icon>error</mat-icon>
      <p>Il n'y a pas de véhicules</p>
    </div>
  </div>
</ng-template>
