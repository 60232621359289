import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user/user.service';

const LINKS = [
  {
    label: 'Accueil',
    path: '/home',
  },
  {
    label: 'Nos ventes',
    path: '/car',
  },
  {
    label: 'Nos services',
    path: '/about/service',
  },
  {
    label: 'Carte grise',
    path: '/about/registration',
  },
  {
    label: 'Contact',
    path: '/about/contact',
  },
];

@Component({
  selector: 'autoclean-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  links = LINKS;
  isAuthenticated: Credential = {} as Credential;

  constructor(private userService: UserService, private router: Router) {}

  loginOrLogoutButton(): void {
    if (this.isAuthenticated) {
      this.userService.logout();
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit(): void {
    this.userService.currentAuthStatus.subscribe((authStatus) => {
      this.isAuthenticated = authStatus;
    });
  }

  signOut(): void {
    this.userService.logout();
  }

  ngOnDestroy(): void {
    console.log('HeaderComponent destroyed');
  }
}
