<ng-container
  *ngIf="listCarMake?.length > 0 && listOptions?.length > 0; else elseLoading"
>
  <h1 mat-dialog-title>Ajouter un véhicule</h1>
  <mat-dialog-content fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1rem">
    <div fxFlex="70" fxFlex.lt-md="100">
      <mat-tab-group animationDuration="0ms" [selectedIndex]="selected">
        <mat-tab label="Détails">
          <form
            [formGroup]="formCarDetails"
            fxLayout="row wrap"
            fxLayoutGap="1rem grid"
            fxFlexFill
          >
            <div fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
              <mat-form-field fxFill appearance="standard">
                <mat-label>Marque</mat-label>
                <mat-select formControlName="car_make">
                  <mat-option
                    *ngFor="let carMake of listCarMake"
                    [value]="carMake.sales"
                  >
                    {{ carMake.sales }}
                  </mat-option>
                </mat-select>
                <mat-hint>Champ obligatoire</mat-hint>
              </mat-form-field>
            </div>

            <div fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
              <mat-form-field fxFill appearance="standard">
                <mat-label>Modèle</mat-label>
                <mat-select formControlName="car_model">
                  <ng-template [ngIf]="f_details?.car_make?.value">
                    <mat-option
                      *ngFor="
                        let model of listCarMake[returnCarMakeIndex()].models
                      "
                      [value]="model"
                    >
                      {{ model }}
                    </mat-option>
                  </ng-template>
                </mat-select>
                <mat-hint *ngIf="!f_details.car_make.value"
                  >Choisir une marque</mat-hint
                >
              </mat-form-field>
            </div>

            <div fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
              <mat-form-field fxFill appearance="standard">
                <mat-label>Description</mat-label>
                <input matInput formControlName="car_description" />
              </mat-form-field>
            </div>
            <div fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
              <mat-form-field fxFill appearance="standard">
                <mat-label>Année</mat-label>
                <input matInput formControlName="car_year" />
                <mat-hint>Champ obligatoire</mat-hint>
              </mat-form-field>
            </div>
            <div fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
              <mat-form-field fxFill appearance="standard">
                <mat-label>Date de première circulation</mat-label>
                <input matInput formControlName="car_first_registration" />
                <mat-hint>Champ obligatoire</mat-hint>
              </mat-form-field>
            </div>
            <div fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
              <mat-form-field fxFill appearance="standard">
                <mat-label>Prix</mat-label>
                <input matInput formControlName="car_price" type="number" />
                <span matSuffix>&euro;</span>
                <mat-hint>Champ obligatoire</mat-hint>
              </mat-form-field>
            </div>
            <div fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
              <mat-form-field fxFill appearance="standard">
                <mat-label>Carburant</mat-label>
                <mat-select formControlName="car_fuel">
                  <ng-template [ngIf]="f_details.car_fuel">
                    <mat-option *ngFor="let fuel of listFuel" [value]="fuel">
                      {{ fuel }}
                    </mat-option>
                  </ng-template>
                </mat-select>
                <mat-hint>Champ obligatoire</mat-hint>
              </mat-form-field>
            </div>
            <div
              fxFlex="50"
              fxFlex.lt-md="50"
              fxFlex.lt-sm="100"
              fxLayout="row"
              fxLayoutAlign="start center"
            >
              <div fxLayout="column">
                <mat-label>Boîte de vitesse</mat-label>
                <mat-radio-group
                  formControlName="car_gearbox"
                  [color]="'primary'"
                  fxLayoutGap="1rem"
                >
                  <mat-radio-button value="manuelle">Manuelle</mat-radio-button>
                  <mat-radio-button value="automatique"
                    >Automatique</mat-radio-button
                  >
                </mat-radio-group>
              </div>
            </div>
            <div fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
              <mat-form-field fxFill appearance="standard">
                <mat-label>Type de véhicule</mat-label>
                <mat-select formControlName="car_type">
                  <ng-template [ngIf]="f_details.car_type">
                    <mat-option *ngFor="let type of listTypes" [value]="type">
                      {{ type }}
                    </mat-option>
                  </ng-template>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
              <mat-form-field fxFill appearance="standard">
                <mat-label>Nombre de places</mat-label>
                <mat-select formControlName="car_seats">
                  <ng-template [ngIf]="f_details.car_seats">
                    <mat-option *ngFor="let seat of listSeats" [value]="seat">
                      {{ seat }}
                    </mat-option>
                  </ng-template>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
              <mat-form-field fxFill appearance="standard">
                <mat-label>Nombre de portes</mat-label>
                <mat-select formControlName="car_doors">
                  <ng-template [ngIf]="f_details.car_doors">
                    <mat-option *ngFor="let door of listDoors" [value]="door">
                      {{ door }}
                    </mat-option>
                  </ng-template>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
              <mat-form-field fxFill appearance="standard">
                <mat-label>Puissance fiscal</mat-label>
                <input
                  matInput
                  formControlName="car_fiscal_power"
                  type="number"
                />
                <span matSuffix>Cv</span>
              </mat-form-field>
            </div>
            <div fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
              <mat-form-field fxFill appearance="standard">
                <mat-label>Puissance moteur</mat-label>
                <input matInput formControlName="car_din_power" type="number" />
                <span matSuffix>Ch</span>
              </mat-form-field>
            </div>
            <div fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
              <mat-form-field fxFill appearance="standard">
                <mat-label>Kilométrage</mat-label>
                <input matInput formControlName="car_mileage" type="number" />
                <span matSuffix>km</span>
                <mat-hint>Champ obligatoire</mat-hint>
              </mat-form-field>
            </div>
            <div fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
              <mat-form-field fxFill appearance="standard">
                <mat-label>Couleur</mat-label>
                <mat-select formControlName="car_color">
                  <ng-template [ngIf]="f_details.car_color">
                    <mat-option
                      *ngFor="let color of listColors"
                      [value]="color"
                    >
                      {{ color | titlecase }}
                    </mat-option>
                  </ng-template>
                </mat-select>
              </mat-form-field>
            </div>
          </form>
        </mat-tab>
        <mat-tab label="Options">
          <form
            *ngIf="listOptions && listOptions.length > 0"
            [formGroup]="formCarDetails"
            fxLayout="row wrap"
            fxLayoutGap="1rem grid"
            fxFlexFill
          >
            <div fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
              <autoclean-checkbox-list
                [formGroup]="formCarDetails"
                [controlName]="'car_options_security'"
                [carOptions]="listOptions[0]"
              >
              </autoclean-checkbox-list>
            </div>
            <div fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
              <autoclean-checkbox-list
                [formGroup]="formCarDetails"
                [controlName]="'car_options_interior_options'"
                [carOptions]="listOptions[1]"
              >
              </autoclean-checkbox-list>
            </div>
            <div fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
              <autoclean-checkbox-list
                [formGroup]="formCarDetails"
                [controlName]="'car_options_info_options'"
                [carOptions]="listOptions[2]"
              >
              </autoclean-checkbox-list>
            </div>
            <div fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
              <autoclean-checkbox-list
                [formGroup]="formCarDetails"
                [controlName]="'car_options_other_options'"
                [carOptions]="listOptions[3]"
              >
              </autoclean-checkbox-list>
            </div>
          </form>
        </mat-tab>
        <mat-tab label="Images">
          <div
            *ngIf="listImages.length > 0; else elseTemplate"
            cdkDropList
            class="images-list-container"
          >
            <div fxLayout="row wrap">
              <div class="main" fxFlex="100">
                <img
                  *ngIf="mainImage !== 'loading'; else imageLoadingTemplate"
                  class="main-image"
                  [src]="mainImage"
                  width="150px"
                  height="150px"
                />
              </div>
            </div>
          </div>
          <div
            *ngIf="listImages.length > 0"
            fxLayout="row wrap"
            class="images-list-container-thumbnail"
            cdkDropList
            [cdkDropListData]="listImages"
            cdkDropListOrientation="horizontal"
            (cdkDropListDropped)="drop($event)"
          >
            <div
              class="images-list-container-thumbnail-container"
              *ngFor="let image of listImages; let i = index"
            >
              <img
                *ngIf="image !== 'loading'; else imageLoadingTemplate"
                cdkDrag
                [src]="image"
                width="100px"
                height="100px"
                (click)="choose(image)"
                class="c-pointer"
                [ngClass]="{ active: mainImage === image }"
              />
              <span [hidden]="mainImage === 'loading'">{{ i + 1 }}</span>
            </div>
          </div>
          <button
            *ngIf="listImages.length > 0"
            class="button primary"
            (click)="fileInput.click()"
            [disabled]="mainImage === 'loading'"
          >
            Choose File
          </button>

          <ng-template #elseTemplate>
            <div
              class="images-list-container c-pointer"
              fxLayout="row"
              fxLayoutAlign="center center"
              (click)="fileInput.click()"
            >
              Cliquer pour ajouter une image
            </div>
          </ng-template>
          <input
            hidden
            hidden
            (change)="addImages($event)"
            #fileInput
            type="file"
            accept="image/*"
            id="file"
          />
        </mat-tab>
      </mat-tab-group>
    </div>
    <div fxFlex="30" fxFlex.lt-md="100" fxHide.lt-md>
      <div
        *ngIf="listImages.length > 0; else elseTemplate"
        class="images-list-container"
      >
        <div fxLayout="row wrap" fxFill>
          <div class="main" fxFlex="100">
            <img
              *ngIf="mainImage !== 'loading'; else imageLoadingTemplate"
              class="main-image"
              [src]="mainImage"
              width="150px"
              height="150px"
            />
          </div>
        </div>
      </div>

      <div
        *ngIf="listImages.length > 0"
        fxLayout="row wrap"
        class="images-list-container-thumbnail"
        cdkDropList
        [cdkDropListData]="listImages"
        cdkDropListOrientation="horizontal"
        (cdkDropListDropped)="drop($event)"
      >
        <div
          class="images-list-container-thumbnail-container"
          *ngFor="let image of listImages; let i = index"
        >
          <img
            *ngIf="image !== 'loading'; else imageLoadingTemplate"
            cdkDrag
            [src]="image"
            width="100px"
            height="100px"
            (click)="choose(image)"
            class="c-pointer"
            [ngClass]="{ active: mainImage === image }"
          />
          <span [hidden]="mainImage === 'loading'">{{ i + 1 }}</span>
        </div>
      </div>
      <button
        *ngIf="listImages.length > 0"
        class="button primary"
        (click)="fileInput.click()"
        [disabled]="mainImage === 'loading'"
      >
        Ajouter une image
      </button>

      <ng-template #elseTemplate>
        <div
          class="images-list-container c-pointer"
          fxLayout="row"
          fxLayoutAlign="center center"
          (click)="fileInput.click()"
        >
          Cliquer pour ajouter une image
        </div>
      </ng-template>
      <input
        hidden
        hidden
        (change)="addImages($event)"
        #fileInput
        type="file"
        accept="image/*"
        id="file"
      />
    </div>
  </mat-dialog-content>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="1rem"
  >
    <button
      class="button alt"
      mat-button
      (click)="onClose()"
      cdkFocusInitial
      [disabled]="mainImage === 'loading'"
    >
      Fermer
    </button>
    <button
      mat-button
      cdkFocusInitial
      (click)="onSubmit($event)"
      [disabled]="mainImage === 'loading'"
    >
      {{ data ? "Enregistrer" : "Publier" }}
    </button>
  </div>
</ng-container>

<ng-template #elseLoading>
  <autoclean-loader></autoclean-loader>
</ng-template>

<ng-template #imageLoadingTemplate>
  <div style="height: 100%" fxLayout="row" fxLayoutAlign="center center" fxFill>
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>
