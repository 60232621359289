<ng-container *ngIf="listOfCars">
  <div fxLayout="row wrap" fxLayoutGap="1rem grid">
    <div
      fxFlex="33"
      fxFlex.lt-md="50"
      fxFlex.lt-sm="100"
      *ngFor="let car of listOfCars"
    >
      <section class="box">
        <div class="admin-panel" *ngIf="isAuthenticated">
          <ul
            class="actions"
            fxLayout="row"
            fxLayoutAlign="end center"
            fxLayoutGap="0.5rem"
          >
            <li>
              <a class="icon primary c-pointer" (click)="update(car)"
                ><mat-icon aria-hidden="false" aria-label="Example home icon"
                  >edit</mat-icon
                >
              </a>
            </li>
            <li>
              <a class="icon primary c-pointer" (click)="toggleCarSoldOut(car)"
                ><mat-icon aria-hidden="false" aria-label="Example home icon">{{
                  car.isSoldout ? "remove_shopping_cart" : "shopping_cart"
                }}</mat-icon></a
              >
            </li>
            <li>
              <a (click)="deleteCar(car)" class="icon primary c-pointer"
                ><mat-icon aria-hidden="false" aria-label="Example home icon"
                  >delete</mat-icon
                ></a
              >
            </li>
          </ul>
        </div>
        <div [ngClass]="{ soldout: car.isSoldout }"></div>
        <div
          class="image featured"
          *ngIf="car?.car_image.length > 0; else elseNoImage"
        >
          <swiper class="mySwiper" [config]="config">
            <ng-template swiperSlide *ngFor="let img of car?.car_image">
              <a class="image" [routerLink]="['/car', car.id, 'detail']">
                <img [src]="img" alt="véhicule" />
              </a>
            </ng-template>
          </swiper>
        </div>
        <ng-template #elseNoImage>
          <a class="image featured" [routerLink]="['/car', car.id, 'detail']">
            <img [src]="'/assets/images/autoclean.png'" alt="" />
          </a>
        </ng-template>

        <header
          [routerLink]="['/car', car.id, 'detail']"
          class="car-detail-header c-pointer"
        >
          <h3>{{ car.car_make }}&nbsp;{{ car.car_model }}</h3>
          <h4>{{ car.car_description }}</h4>

          <!-- <i>{{ car.date | date: "shortDate" }}</i> -->
        </header>
        <div>Année : {{ car.car_year }}</div>
        <div>Kilométrage : {{ car.car_mileage | number }}</div>
        <div>Puissance fiscale : {{ car.car_fiscal_power | number }}</div>
        <h4>Prix : {{ car.car_price | currency: "EUR" }}</h4>
        <footer>
          <ul class="actions">
            <li>
              <a [routerLink]="['/car', car.id, 'detail']" class="button alt"
                >Voir le véhicule</a
              >
            </li>
          </ul>
        </footer>
      </section>
    </div>
  </div>
</ng-container>
