import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { CarMake } from '../../models/car';

@Injectable({
  providedIn: 'root',
})
export class CarMakeService {
  constructor(private angularFirestore: AngularFirestore) {}

  collection = this.angularFirestore.collection<CarMake>('makes');

  getCarMakes(): Observable<CarMake[]> {
    return this.collection.snapshotChanges().pipe(
      take(1),
      map((actions) => {
        return actions.map((options) => {
          const data = options.payload.doc.data() as CarMake;
          const id = options.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }
}
