import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import * as watermark from 'watermarkjs';

@Directive({
  selector: '[autocleanMockImage]',
})
export class MockImageDirective implements OnInit {
  @Input('autocleanMockImage') base64 = '';

  constructor(private el: ElementRef, public renderer: Renderer2) {}

  ngOnInit(): void {
    this.addElement('/assets/images/autoclean.png');

    if (this.base64) {
      const file = this.convertToFile(`data:image/jpg;base64, ${this.base64}`);

      watermark([file, '/assets/images/autoclean-watermark.png'])
        .image(watermark.image.lowerRight(0.5))
        .then(async (watermark: any) => {
          if (watermark.src) {
            const watermarkFile = watermark.src;
            await this.addElement(watermarkFile);
          }
        });
    }
  }

  addElement(src: string): void {
    this.renderer.setAttribute(this.el.nativeElement, 'src', src);
  }

  convertToFile(base64: string): File {
    const arr = base64.split(',') as any;
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'image.jpg', { type: mime });
  }
}
