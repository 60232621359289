import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { CarService } from '../services/car/car.service';

@Injectable({
  providedIn: 'root',
})
export class IsCarIdentityGuard implements CanActivate {
  constructor(private carService: CarService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const carId = route.params.id;
    const car = await this.carService.getCarById(carId).toPromise();
    if (!car) {
      this.router.navigate(['/']);
    }
    return !!car;
  }
}
