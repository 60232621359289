import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[autocleanImageToBase64]',
})
export class ImageToBase64Directive implements OnInit, OnChanges {
  @Input('autocleanImageToBase64') image: File = {} as File;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {
    this.convertToBase64(this.image);
  }

  ngOnChanges(): void {
    this.convertToBase64(this.image);
  }

  convertToBase64(image: File): void {
    const reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsBinaryString(image);
  }

  transform(string64: string): any {
    return `data:image/jpg;base64, ${string64}`;
  }

  _handleReaderLoaded(readerEvt: any): void {
    const binaryString = readerEvt.target.result;
    const base64 = btoa(binaryString);

    this.renderer.setAttribute(
      this.el.nativeElement,
      'src',
      this.transform(base64)
    );
  }
}
