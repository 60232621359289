<!-- Footer -->
<section id="footer">
  <div class="container">
    <div
      fxLayout="row wrap"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between start"
      fxLayoutAlign.lt-md="start stretch"
      fxLayoutGap="2rem grid"
      fxLayoutGap.lt-md="0 grid"
    >
      <div fxFlex="60" fxFlexOrder.lt-md="2">
        <section>
          <header>
            <h2>{{ "Où nous trouver" | uppercase }}</h2>
          </header>
          <ul class="contact">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2640.446165486103!2d7.738110215663958!3d48.56300287925961!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4796c9bcc1e589c9%3A0xe4dc2900d462126e!2s19%20Rue%20la%20Fayette%2C%2067100%20Strasbourg!5e0!3m2!1sfr!2sfr!4v1635020832369!5m2!1sfr!2sfr"
              width="100%"
              height="250"
              style="border: 0"
              allowfullscreen
              loading="lazy"
            ></iframe>
            <li>
              <h3>Adresse</h3>
              <p>
                19 Rue la Fayette<br />
                67100 Strasbourg
              </p>
            </li>
            <li>
              <h3>Horaire</h3>
              <p>
                Lun - Ven : 9h - 18h<br />
                Sam : 9h - 12h<br />
                Dim : Fermé
              </p>
            </li>
          </ul>
        </section>
      </div>

      <div
        fxFlex="33"
        fxFlexOrder.lt-md="1"
        fxLayout="column"
        fxLayoutGap="2rem grid"
        fxLayoutGap.lt-md="2rem grid"
      >
        <section>
          <header>
            <h2>{{ "Nous contacter" | uppercase }}</h2>
          </header>
          <p>
            Notre équipe chaleureuse et professionnelle est à votre écoute pour
            vous offrir le meilleur des services.
          </p>
          <ul class="contact">
            <li>
              <h3>Téléphone 1</h3>
              <p>
                <a href="tel:+33771289898">07 71 28 98 98</a>
              </p>
            </li>
            <li>
              <h3>Téléphone 2</h3>
              <p>
                <a href="tel:+33762629912">07 62 62 99 12</a>
              </p>
            </li>
            <li>
              <h3>Email</h3>
              <p>
                <a routerLink="about/contact">autoclean67200@gmail.com</a>
              </p>
            </li>
          </ul>
        </section>

        <section>
          <header>
            <h2>{{ "Nos réseaux" | uppercase }}</h2>
          </header>
          <ul class="social">
            <li>
              <a
                href="https://www.facebook.com/autocleandelameinau"
                target="_blank"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="facebook"
              >
                <fa-icon
                  class="fa-2x"
                  style="color: white"
                  [icon]="['fab', 'facebook-f']"
                ></fa-icon>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/auto.clean67/"
                target="_blank"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="instagram"
              >
                <fa-icon
                  class="fa-2x"
                  style="color: white"
                  [icon]="['fab', 'instagram']"
                ></fa-icon>
              </a>
            </li>
            <li>
              <a
                href="http://m.me/autocleandelameinau"
                target="_blank"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="facebook-messenger"
              >
                <fa-icon
                  class="fa-2x"
                  style="color: white"
                  [icon]="['fab', 'facebook-messenger']"
                ></fa-icon>
              </a>
            </li>
            <li>
              <a
                href="https://wa.me/33771289898?text=Je%20suis%20intéressé%20par%20votre%20voiture%20à%20vendre"
                target="_blank"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="whatsapp"
              >
                <fa-icon
                  class="fa-2x"
                  style="color: white"
                  [icon]="['fab', 'whatsapp']"
                ></fa-icon
              ></a>
            </li>
          </ul>
        </section>
      </div>

      <div fxFlex="100" fxFlexOrder.lt-md="3">
        <div id="copyright">
          <ul class="links">
            <li>Copyright &copy; 2021</li>
            <li>Tout droit réservé : Auto Clean de la Meinau</li>
            <li>
              Made by&nbsp;<a href="http://talipcesur.fr/" target="_blank"
                >Talip Cesur</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
