import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ToWatermarkPipe } from 'src/app/shared/pipes/to-watermark.pipe';
import { Car } from '../../models/car';
import { ImageStorageService } from '../image-storage/image-storage.service';

@Injectable({
  providedIn: 'root',
})
export class CarService {
  constructor(
    private firestore: AngularFirestore,
    private imageStorageService: ImageStorageService,
    private toWatermarkPipe: ToWatermarkPipe
  ) {}

  collection = this.firestore.collection<Car>('cars');

  getCars(): Observable<Car[]> {
    return this.collection.snapshotChanges().pipe(
      take(1),
      map((actions) => {
        return actions.map((car) => {
          const data = car.payload.doc.data() as Car;
          const id = car.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  getCarById(id: string): Observable<Car | null> {
    return this.collection
      .doc(id)
      .get()
      .pipe(
        take(1),
        map((car) => {
          if (car.exists) {
            const data = car.data() as Car;
            const id = car.id;
            return { id, ...data };
          } else {
            return null;
          }
        })
      );
  }

  createCar(car: Car): Promise<any> {
    car.createdAt = new Date();
    return this.collection.add(car);
  }

  updateCar(car: Car): Promise<void> {
    car.updatedAt = new Date();
    const { id, ...rest } = car;
    return this.collection.doc(id).update(rest);
  }

  setCar(car: Car): Promise<void> {
    const { id, ...rest } = car;
    return this.collection.doc(id).set(rest);
  }

  deleteCar(car: Car): Promise<void> {
    return this.collection.doc(car.id).delete();
  }
}
