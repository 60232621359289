import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from '@firebase/util';
import { finalize, last, switchMap } from 'rxjs/operators';
import { FileUpload } from '../../models/file-upload';

@Injectable({
  providedIn: 'root',
})
export class ImageStorageService {
  uploadPercent$: Observable<any> = {} as Observable<any>;
  downloadURL$: Observable<any> = {} as Observable<any>;

  storageUrl =
    'https://firebasestorage.googleapis.com/v0/b/angular-firebase-storage.appspot.com/o/';
  basePath = '/uploads';

  constructor(
    private storage: AngularFireStorage,
    private firestore: AngularFirestore
  ) {}

  public uploadBase64ToStorage(
    fileUploadBase64: string,
    id: string,
    index: number
  ): Observable<any> {
    const filePath = `${this.basePath}/autoclean-${id}-${index}`;
    const storageRef = this.storage.ref(filePath);
    const putStringTask = storageRef.putString(fileUploadBase64, 'data_url', {
      contentType: 'image/jpg',
    });

    console.log('putStringTask', putStringTask);

    // @ts-ignore
    return putStringTask.snapshotChanges().pipe(
      last(),
      switchMap(() => {
        return storageRef.getDownloadURL();
      }),
      finalize(() => {
        storageRef.getDownloadURL().subscribe(async (downloadURL) => {
          const fileUpload: FileUpload = {
            key: id,
            name: `autoclean-${id}-${index}.jpg`,
            url: downloadURL,
          };

          this.saveToFirestore(fileUpload);
        });
      })
    );
  }

  private async saveToFirestore(fileUpload: FileUpload): Promise<void> {
    const { file, ...fileUploadRest } = fileUpload;
    await this.firestore
      .collection(`${this.basePath}`)
      .add(fileUploadRest)
      .then(() => {
        console.log('File Uploaded');
      })
      .catch((err) => {
        console.log('Error while uploading file', err);
      });
  }

  private async deleteFromFirestoreFormUrl(imageUrl: string): Promise<void> {
    await this.firestore
      .collection(`${this.basePath}`)
      .ref.where('url', '==', imageUrl)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
  }

  // delete image from firebase storage
  public async deleteImage(imageUrl: string): Promise<any> {
    const imageRef = this.storage.refFromURL(imageUrl);

    await imageRef
      .delete()
      .toPromise()
      .then(() => {
        this.deleteFromFirestoreFormUrl(imageUrl).then(() => {
          console.log('File deleted');
        });
      });
  }
}
