import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Car } from 'src/app/core/models/car';
import { CarService } from 'src/app/core/services/car/car.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { DialogCarFormComponent } from 'src/app/shared/components/dialog/dialog-car-form/dialog-car-form.component';
import { carList } from 'src/app/shared/mock/cars.mock';

@Component({
  selector: 'autoclean-car',
  templateUrl: './car.component.html',
  styleUrls: ['./car.component.scss'],
})
export class CarComponent implements OnInit {
  carList = carList.sort(() => Math.random() - 0.5);
  carsList: Car[] = [];
  currentPage = 1;

  isAuthenticated = '';

  constructor(
    private carService: CarService,
    private dialog: MatDialog,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.userService.currentAuthStatus.subscribe((authStatus) => {
      this.isAuthenticated = authStatus;
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.router.navigate(['/car', params.page ?? 1]);
    });

    this.carService.getCars().subscribe((cars: Car[]) => {
      if (cars) {
        this.carsList = cars;
      }
    });
  }

  add(): void {
    const dialogRef = this.dialog.open(DialogCarFormComponent, {
      width: '100%',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        console.log(result);

        this.carService.createCar(result).then(async () => {
          await this.carService.getCars().subscribe((cars: Car[]) => {
            if (cars) {
              this.carsList = cars;
            }
          });
        });
      }
    });

    console.log('carlist', this.carsList);
  }
}
