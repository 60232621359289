<section id="main">
  <div class="container">
    <div fxLayout="column">
      <ul
        class="actions"
        fxLayout="row"
        fxLayoutAlign="end center"
        fxFlex="100"
        fxLayoutGap="1rem"
        *ngIf="isAuthenticated"
      >
        <li>
          <a
            class="button icon"
            fxLayout="row"
            fxLayoutAlign="center center"
            (click)="add()"
          >
            <mat-icon aria-hidden="false" aria-label="Example home icon"
              >add</mat-icon
            >
            <span>Ajouter</span>
          </a>
        </li>
      </ul>

      <autoclean-car-list
        *ngIf="carsList.length > 0; else elseLoader"
        [title]="'Nos ventes de véhicules'"
        [cars]="carsList"
      ></autoclean-car-list>

      <ng-template #elseLoader>
        <autoclean-loader></autoclean-loader>
      </ng-template>
    </div>
  </div>
</section>
