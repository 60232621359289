<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <div>
    <p>{{ data.message }}</p>
  </div>
</div>
<div mat-dialog-actions fxLayoutGap="20px">
  <button mat-button [mat-dialog-close]="false">{{ data.cancel }}</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>
    {{ data.confirm }}
  </button>
</div>
