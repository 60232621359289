import { DatePipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { CoreModule } from './core/core.module';
import { getPaginatorIntl } from './core/intl/paginator-intl';
import { CarModule } from './modules/car/car.module';
import { HomepageModule } from './modules/homepage/homepage.module';
import { ToBase64Pipe } from './shared/pipes/to-base64.pipe';
import { ToWatermarkPipe } from './shared/pipes/to-watermark.pipe';
registerLocaleData(localeFr);

const MODULES = [
  BrowserModule,
  AppRoutingModule,
  BrowserAnimationsModule,
  CoreModule,
  HomepageModule,
  CarModule,
  AngularFireModule.initializeApp(environment.firebaseConfig),
  AngularFireAuthModule,
  AngularFirestoreModule,
  AngularFireStorageModule,
];

// function initApp() {
//   return (userService: UserService) => {
//     return new Promise((resolve, reject) => {
//       if (userService.isLoggedIn) {
//         console.log('User is logged in', userService.isLoggedIn);
//         resolve(userService.isLoggedIn);
//       } else {
//         reject();
//       }
//     });
//   };
// }
@NgModule({
  declarations: [AppComponent],
  imports: [...MODULES, FontAwesomeModule],
  providers: [
    DatePipe,
    ToBase64Pipe,
    ToWatermarkPipe,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initApp,
    //   deps: [AngularFireAuthModule, UserService],
    //   multi: true,
    // },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: MatPaginatorIntl, useValue: getPaginatorIntl() },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
