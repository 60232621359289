import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CarOptions } from '../../models/car';

@Injectable({
  providedIn: 'root',
})
export class CarOptionsService {
  constructor(private angularFirestore: AngularFirestore) {}

  collection = this.angularFirestore.collection<CarOptions>('car_options');

  getCarOptions(): Observable<CarOptions[]> {
    return this.collection.snapshotChanges().pipe(
      take(1),
      map((actions) => {
        return actions.map((options) => {
          const data = options.payload.doc.data() as CarOptions;
          const id = options.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }
}
