<!-- Header -->
<section id="header" fxLayoutAlign="center start">
  <!-- Logo -->

  <!-- Nav -->
  <nav id="nav" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="90">
    <img
      src="assets/images/autoclean.png"
      [routerLink]="'/home'"
      class="c-pointer image"
      width="150"
      height="150"
    />

    <ul fxHide.lt-md>
      <ng-template ngFor let-link [ngForOf]="links">
        <li>
          <a [routerLink]="[link.path]" routerLinkActive="active">{{
            link.label
          }}</a>
        </li>
      </ng-template>
      <li>
        <button
          class="button button-connexion alt"
          (click)="loginOrLogoutButton()"
        >
          {{ isAuthenticated ? "Déconnexion" : "Connexion" }}
        </button>
      </li>
    </ul>

    <button
      fxHide.gt-sm
      class="menu"
      mat-icon-button
      [matMenuTriggerFor]="menu"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <ng-template ngFor let-link [ngForOf]="links">
        <a mat-menu-item [routerLink]="[link.path]" routerLinkActive="active">{{
          link.label
        }}</a>
      </ng-template>
      <a mat-menu-item class="logout" (click)="loginOrLogoutButton()">
        {{ isAuthenticated ? "Déconnexion" : "Connexion" }}
      </a>
    </mat-menu>
  </nav>
</section>
