<section id="banner" fxLayoutAlign="center">
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="3rem">
    <header>
      <img src="assets/images/autoclean.png" width="250" height="250" />
    </header>
    <div fxLayout="column">
      <h2>Erreur 404</h2>
      <p>La page que vous recherchez n'existe pas.</p>

      <a
        [routerLink]="['/']"
        class="button alt"
        fxLayoutAlign="center center"
        fxLayoutGap="1rem"
      >
        <mat-icon>keyboard_backspace</mat-icon>
        <span>Revenir à l'accueil</span>
      </a>
    </div>
  </div>
</section>
