import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SwiperModule } from 'swiper/angular';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from './material.module';

const MODULES = [
  CommonModule,
  FlexLayoutModule,
  MaterialModule,
  RouterModule,
  SharedModule,
  ReactiveFormsModule,
  DragDropModule,
  SwiperModule,
  FontAwesomeModule,
  FormsModule,
  HttpClientModule,
];

@NgModule({
  declarations: [],
  imports: [...MODULES],
  exports: [...MODULES],
})
export class CoreModule {}
