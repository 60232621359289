import { Component, OnInit } from '@angular/core';
import { Car } from 'src/app/core/models/car';
import { CarService } from 'src/app/core/services/car/car.service';

@Component({
  selector: 'autoclean-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  lastSoldOut: Car[] = [];
  lastNewCars: Car[] = [];

  constructor(private carService: CarService) {}

  ngOnInit(): void {
    this.carService.getCars().subscribe((cars: Car[]) => {
      if (cars) {
        this.lastSoldOut = cars.filter((car: Car) => car.isSoldout).slice(0, 6);
        this.lastNewCars = cars
          .filter((car: Car) => !car.isSoldout)
          .slice(0, 6);
      }
    });
  }
}
