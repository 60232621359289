import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, Inject } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { combineLatest, Observable, Subject, timer } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { Car, CarMake, CarOptions } from 'src/app/core/models/car';
import { CarOptionsService } from 'src/app/core/services/car-options/car-options.service';
import { ImageStorageService } from 'src/app/core/services/image-storage/image-storage.service';
import { CarMakeService } from 'src/app/core/services/make/make.service';
import { ToWatermarkPipe } from 'src/app/shared/pipes/to-watermark.pipe';
import { DialogCarConfirmComponent } from '../dialog-car-confirm/dialog-car-confirm.component';

@Component({
  selector: 'autoclean-dialog-car-form',
  templateUrl: './dialog-car-form.component.html',
  styleUrls: ['./dialog-car-form.component.scss'],
})
export class DialogCarFormComponent {
  destroy$: Subject<boolean> = new Subject<boolean>();

  listCarMake$: Observable<CarMake[]>;
  listOptions$: Observable<CarOptions[]>;

  listCarMake: CarMake[] = [];
  listOptions: CarOptions[] = [];
  listFuel: string[] = [
    'Essence',
    'Diesel',
    'Hybride',
    'Electrique',
    'GPL',
    'Autre',
  ];
  listTypes: string[] = ['SUV', 'Citadine', 'Berline', 'Break', 'Cabriolet'];
  listSeats: number[] = [1, 2, 3, 4, 5, 6, 7];
  listDoors: number[] = [2, 3, 4, 5, 6, 7];
  listColors: string[] = [
    'argent',
    'beige',
    'blanc',
    'bleu',
    'bordeaux',
    'gris',
    'ivoire',
    'jaune',
    'marron',
    'noir',
    'orange',
    'rose',
    'rouge',
    'vert',
    'violet',
    'autre',
  ];

  formCarDetails: FormGroup = {} as FormGroup;
  listImages: string[] = [];
  mainImage = '';
  selected = 0;
  image = '';
  uid = Math.random().toString(36).substring(2, 15);
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<DialogCarFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Car,
    private formBuilder: FormBuilder,
    private carOptionsService: CarOptionsService,
    private carMakeService: CarMakeService,
    private dialog: MatDialog,
    private imageStorageService: ImageStorageService,
    private toWatermarkPipe: ToWatermarkPipe
  ) {
    this.listCarMake$ = this.carMakeService.getCarMakes();
    this.listOptions$ = this.carOptionsService.getCarOptions();
  }

  ngOnInit(): void {
    combineLatest([this.listCarMake$, this.listOptions$])
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        finalize(() => (this.initForm(), this.initImage(), this.onChanges()))
      )
      .subscribe(async ([carMakes, carOptions]) => {
        this.listCarMake = carMakes;
        this.listOptions = carOptions;
      });
  }

  initForm(): void {
    this.formCarDetails = this.formBuilder.group({
      car_make: [
        { value: this?.data?.car_make ?? '', disabled: false },
        Validators.required,
      ],
      car_model: [
        {
          value: this?.data?.car_model ?? '',
          disabled: this?.data?.car_make ? false : true,
        },
        Validators.required,
      ],
      car_description: [
        { value: this?.data?.car_description ?? '', disabled: false },
        Validators.required,
      ],
      car_year: [
        { value: this?.data?.car_year ?? '', disabled: false },
        Validators.required,
      ],
      car_first_registration: [
        { value: this?.data?.car_first_registration ?? '', disabled: false },
        Validators.required,
      ],
      car_fuel: [
        { value: this?.data?.car_fuel ?? '', disabled: false },
        Validators.required,
      ],
      car_gearbox: [
        { value: this?.data?.car_gearbox ?? '', disabled: false },
        Validators.required,
      ],
      car_type: [
        { value: this?.data?.car_type ?? '', disabled: false },
        Validators.required,
      ],
      car_seats: [
        { value: this?.data?.car_seats ?? '', disabled: false },
        Validators.required,
      ],
      car_doors: [
        { value: this?.data?.car_doors ?? '', disabled: false },
        Validators.required,
      ],
      car_fiscal_power: [
        { value: this?.data?.car_fiscal_power ?? '', disabled: false },
        Validators.required,
      ],
      car_din_power: [
        { value: this?.data?.car_din_power ?? '', disabled: false },
        Validators.required,
      ],
      car_mileage: [
        { value: this?.data?.car_mileage ?? '', disabled: false },
        Validators.required,
      ],
      car_color: [
        { value: this?.data?.car_color ?? '', disabled: false },
        Validators.required,
      ],
      car_price: [
        { value: this?.data?.car_price ?? '', disabled: false },
        Validators.required,
      ],
      car_images: this.formBuilder.array([
        this?.data?.car_image && this.data.car_image.length > 0
          ? this.formBuilder.control(this.data.car_image)
          : null,
      ]),
      car_options_security: this.formBuilder.array([
        this?.data?.car_options_security &&
        this.data.car_options_security.length > 0
          ? this.formBuilder.control(this.data.car_options_security)
          : null,
      ]),
      car_options_interior_options: this.formBuilder.array([
        this?.data?.car_options_interior_options &&
        this.data.car_options_interior_options.length > 0
          ? this.formBuilder.control(this.data.car_options_interior_options)
          : null,
      ]),
      car_options_info_options: this.formBuilder.array([
        this?.data?.car_options_info_options &&
        this.data.car_options_info_options.length > 0
          ? this.formBuilder.control(this.data.car_options_info_options)
          : null,
      ]),
      car_options_other_options: this.formBuilder.array([
        this?.data?.car_options_other_options &&
        this.data.car_options_other_options.length > 0
          ? this.formBuilder.control(this.data.car_options_other_options)
          : null,
      ]),
    });
  }

  initImage(): void {
    if (this?.data?.car_image && this.data.car_image.length > 0) {
      this.mainImage = this.data?.car_image[0];
      this.listImages = this.data.car_image;
    }
  }

  returnCarMakeIndex(): number {
    const index = this.listCarMake.findIndex(
      (carMake) => carMake.sales === this.f_details.car_make.value
    );
    return index;
  }

  onChanges(): void {
    this.f_details.car_make.valueChanges.subscribe(() => {
      this.f_details.car_model.enable();
      this.f_details.car_model.reset();
    });
  }

  onSubmit(event: Event): void {
    event.preventDefault();

    if (this.formCarDetails.invalid) {
      this.formCarDetails.markAllAsTouched();
      return;
    } else {
      const car: Car = {
        car_make: this.f_details.car_make.value,
        car_model: this.f_details.car_model.value,
        car_description: this.f_details.car_description.value,
        car_year: this.f_details.car_year.value,
        car_first_registration: this.f_details.car_first_registration.value,
        car_fuel: this.f_details.car_fuel.value,
        car_gearbox: this.f_details.car_gearbox.value,
        car_type: this.f_details.car_type.value,
        car_seats: this.f_details.car_seats.value,
        car_doors: this.f_details.car_doors.value,
        car_fiscal_power: this.f_details.car_fiscal_power.value,
        car_din_power: this.f_details.car_din_power.value,
        car_mileage: this.f_details.car_mileage.value,
        car_color: this.f_details.car_color.value,
        car_price: this.f_details.car_price.value,
        car_image: this.f_details.car_images.value[0] ?? [],
        car_options_security:
          this.f_details.car_options_security.value[0] ?? [],
        car_options_interior_options:
          this.f_details.car_options_interior_options.value[0] ?? [],
        car_options_info_options:
          this.f_details.car_options_info_options.value[0] ?? [],
        car_options_other_options:
          this.f_details.car_options_other_options.value[0] ?? [],
      };

      if (this.data) {
        car.id = this.data.id;
        car.isSoldout = this.data.isSoldout ?? false;
      }

      const dialogRef = this.dialog.open(DialogCarConfirmComponent, {
        data: {
          title: this.data?.id ? 'Enregistrer' : 'Publier',
          message: this.data?.id
            ? 'Voulez-vous vraiment modifier ce véhicule ?'
            : 'Voulez-vous vraiment publier ce véhicule ?',
          confirm: this.data?.id ? 'Enregistrer' : 'Publier',
          cancel: 'Annuler',
        },
        width: '400px',
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          event.stopPropagation();
          this.dialogRef.close(car);
        }
      });

      return;
    }
  }

  onClose(): void {
    this.listImages.forEach((image) => {
      this.imageStorageService.deleteImage(image);
    });

    this.dialogRef.close();
  }

  addImages(event: any): void {
    const files = event.target.files;

    this.mainImage = 'loading';
    this.listImages.push('loading');

    if (files[0]) {
      this.toWatermarkPipe
        .fileToWatermark(files[0])
        .then(async (watermark: string) => {
          await this.imageStorageService
            .uploadBase64ToStorage(
              watermark,
              this.uid,
              this.listImages.length - 1
            )
            .subscribe((downloadURL: string) => {
              timer(1000).subscribe(() => {
                this.listImages[this.listImages.length - 1] = downloadURL;
                this.mainImage = downloadURL;
              });
            });
        });
    }

    this.addToForm();
  }

  choose(image: string): void {
    this.mainImage = image;
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    this.addToForm();
  }

  addToForm(): void {
    this.formImages.removeAt(0);

    setTimeout(() => {
      this.formImages.push(this.formBuilder.control(this.listImages));
    }, 200);
  }

  get f_details(): { [key: string]: AbstractControl } {
    return this.formCarDetails.controls;
  }

  get formImages(): FormArray {
    return this.formCarDetails.get('car_images') as FormArray;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
