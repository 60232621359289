<ng-template #elseTemplate>
  <p>Aucune option disponible</p>
</ng-template>

<ng-template [ngIf]="car">
  <!-- Main -->
  <section id="main">
    <div class="container" fxLayout="column">
      <div fxFlex="100">
        <a
          [routerLink]="['/car']"
          class="link c-pointer"
          fxLayout="row"
          fxLayoutGap="0.5rem"
          fxLayoutAlign="start center"
        >
          <mat-icon>arrow_back</mat-icon>
          <span>Revenir sur la liste</span>
        </a>
      </div>
      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-between start"
        fxLayoutAlign.lt-md="start space-between"
        fxLayoutGap="2rem"
        fxFill.lt-md
      >
        <div fxFlex="70" fxFlex.lt-md="100" class="admin-panel-box">
          <div class="admin-panel" *ngIf="isAuthenticated">
            <ul
              class="actions"
              fxLayout="row"
              fxLayoutAlign="end center"
              fxLayoutGap="0.5rem"
            >
              <li>
                <a class="icon primary c-pointer" (click)="update(car)"
                  ><mat-icon aria-hidden="false" aria-label="Example home icon"
                    >edit</mat-icon
                  >
                </a>
              </li>
              <li>
                <a
                  class="icon primary c-pointer"
                  (click)="toggleCarSoldOut(car)"
                  ><mat-icon
                    aria-hidden="false"
                    aria-label="Example home icon"
                    >{{
                      car.isSoldout ? "remove_shopping_cart" : "shopping_cart"
                    }}</mat-icon
                  ></a
                >
              </li>
              <li>
                <a (click)="deleteCar(car)" class="icon primary c-pointer"
                  ><mat-icon aria-hidden="false" aria-label="Example home icon"
                    >delete</mat-icon
                  ></a
                >
              </li>
            </ul>
          </div>
          <div [ngClass]="{ soldout: car.isSoldout }"></div>

          <article class="box post">
            <div
              class="image featured parent"
              *ngIf="car?.car_image?.length > 0; else elseNoImage"
            >
              <swiper [config]="config" class="mySwiper">
                <ng-template swiperSlide *ngFor="let img of car?.car_image">
                  <a class="image">
                    <img [src]="img" alt="véhicule" />
                  </a>
                </ng-template>
              </swiper>
            </div>

            <ng-template #elseNoImage>
              <a class="image featured">
                <img [src]="'/assets/images/autoclean.png'" alt="" />
              </a>
            </ng-template>
            <header class="car-detail-header">
              <h2>{{ car.car_make }}&nbsp;{{ car.car_model }}</h2>
              <p>{{ car.car_description }}</p>
            </header>
            <hr />
            <section>
              <header>
                <h3>{{ "Sécurité" | titlecase }}</h3>
              </header>
              <ul
                fxLayout="row wrap"
                fxLayoutGap="0.9rem grid"
                fxFlexFill
                *ngIf="car?.car_options_security.length > 0; else elseTemplate"
              >
                <li fxFlex="50%" *ngFor="let item of car.car_options_security">
                  {{ item }}
                </li>
              </ul>
            </section>
            <hr />
            <section>
              <header>
                <h3>{{ "Confort" | titlecase }}</h3>
              </header>
              <ul
                fxLayout="row wrap"
                fxLayoutGap="0.9rem grid"
                fxFlexFill
                *ngIf="
                  car?.car_options_interior_options.length > 0;
                  else elseTemplate
                "
              >
                <li
                  fxFlex="50%"
                  *ngFor="let item of car.car_options_interior_options"
                >
                  {{ item }}
                </li>
              </ul>
            </section>
            <hr />
            <section>
              <header>
                <h3>{{ "Multimédia" | titlecase }}</h3>
              </header>
              <ul
                fxLayout="row wrap"
                fxLayoutGap="0.9rem grid"
                fxFlexFill
                *ngIf="
                  car?.car_options_info_options.length > 0;
                  else elseTemplate
                "
              >
                <li
                  fxFlex="50%"
                  *ngFor="let item of car.car_options_info_options"
                >
                  {{ item }}
                </li>
              </ul>
            </section>
            <hr />
            <section>
              <header>
                <h3>{{ "Autres" | titlecase }}</h3>
              </header>
              <ul
                fxLayout="row wrap"
                fxLayoutGap="0.9rem grid"
                fxFlexFill
                *ngIf="
                  car?.car_options_other_options.length > 0;
                  else elseTemplate
                "
              >
                <li
                  fxFlex="50%"
                  *ngFor="let item of car.car_options_other_options"
                >
                  {{ item }}
                </li>
              </ul>
            </section>
          </article>
        </div>
        <div fxFlex="30" fxFlex.lt-md="100">
          <!-- Sidebar -->
          <section class="box">
            <!-- <a href="#" class="image featured"
            ><img src="images/pic09.jpg" alt=""
          /></a> -->
            <header>
              <h3>Informations</h3>
            </header>
            <table>
              <tr>
                <td>
                  <b>Kilométrage</b>
                </td>
                <td>
                  {{ car.car_mileage }}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Mise en circulation</b>
                </td>
                <td>
                  {{ car.car_first_registration }}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Puissance</b>
                </td>
                <td>
                  {{ car.car_din_power }}<b>ch</b>&nbsp;({{
                    car.car_fiscal_power
                  }}<b>CV</b>)
                </td>
              </tr>
              <tr>
                <td>
                  <b>Carburant</b>
                </td>
                <td>
                  {{ car.car_fuel }}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Couleur</b>
                </td>
                <td>
                  {{ car.car_color | titlecase }}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Nbre de porte</b>
                </td>
                <td>
                  {{ car.car_doors }}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Nbre de place</b>
                </td>
                <td>
                  {{ car.car_seats }}
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  Garantie 6 mois ou 5000 KM
                  <br />
                  <sup>* voir conditions auprès des vendeurs</sup>
                </td>
              </tr>
            </table>

            <footer>
              <div class="price" fxLayout="row" fxLayoutAlign="center">
                <h2>{{ car.car_price | currency: "EUR" }}</h2>
              </div>
            </footer>
          </section>
          <section class="box">
            <header>
              <h3>Ce véhicule vous intéresse&nbsp;?</h3>
            </header>
            <p>Vous pouvez nous contacter pour plus d'informations.</p>
            <footer fxLayout="column" fxLayoutGap="1rem">
              <a href="tel:+33500000000" class="button alt"
                >Appeler maintenant</a
              >
              <a
                [routerLink]="['/about/contact', { car: car.id }]"
                class="button alt"
                >Envoyer un mail</a
              >
            </footer>
          </section>
        </div>
      </div>
    </div>
  </section>
</ng-template>
