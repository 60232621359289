import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Car } from '../models/car';
import { CarService } from '../services/car/car.service';

@Injectable({
  providedIn: 'root',
})
export class CarDetailResolver implements Resolve<Car | null> {
  constructor(private carService: CarService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Car | null> {
    return this.carService.getCarById(route.params.id.toString());
  }
}
