import { Component } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faFacebookF,
  faFacebookMessenger,
  faInstagram,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'autoclean-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  constructor(private library: FaIconLibrary) {
    this.library.addIcons(
      faFacebookF,
      faFacebookMessenger,
      faInstagram,
      faWhatsapp
    );
  }
}
