import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { CarOptions, CarOptionsInterface } from 'src/app/core/models/car';

@Component({
  selector: 'autoclean-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss'],
})
export class CheckboxListComponent implements OnInit {
  @Input() carOptions: CarOptions = {} as CarOptions;
  @Input() formGroup: FormGroup = {} as FormGroup;
  @Input() controlName = '';

  allComplete = false;
  indeterminate = false;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.form.value[0] && this.form.value[0].length > 0) {
      this.initCheckbox();
    }
  }

  initCheckbox(): void {
    const formOpt = this.form.value[0];

    formOpt.forEach((opt: string) => {
      this.carOptions.options[
        this.carOptions.options.findIndex(
          (f: CarOptionsInterface) => f.name === opt
        )
      ].completed = true;
    });

    const completed = this.carOptions.options.filter(
      (f: CarOptionsInterface) => f.completed
    );

    this.form.value[0].length === completed.length
      ? ((this.allComplete = true), (this.indeterminate = false))
      : ((this.allComplete = false), (this.indeterminate = true));
  }

  updateAllComplete(): void {
    this.allComplete =
      this.carOptions.options != null &&
      this.carOptions.options.every(
        (o: { name: string; completed: boolean }) => o.completed
      );

    this.addToForm();
  }

  addToForm(): void {
    this.form.removeAt(0);

    setTimeout(() => {
      const opt = this.carOptions.options.filter(
        (f: CarOptionsInterface) => f.completed
      );
      const opt_filtered = opt.map((f: CarOptionsInterface) => f.name);
      // console.log('opt_filtered', opt_filtered);
      this.form.push(this.fb.control(opt_filtered));
      // console.log('form', this.form.value);
    }, 200);
  }

  someComplete(): boolean {
    if (this.carOptions.options == null) {
      return false;
    }
    return (
      this.carOptions.options.filter(
        (o: { name: string; completed: boolean }) => o.completed
      ).length > 0 && !this.allComplete
    );
  }

  setAll(completed: boolean): void {
    this.allComplete = completed;
    if (this.carOptions.options == null) {
      return;
    }
    this.carOptions.options.forEach(
      (o: { name: string; completed: boolean }) => (o.completed = completed)
    );

    this.addToForm();
  }

  get form(): FormArray {
    return this.formGroup.get(this.controlName) as FormArray;
  }
}
