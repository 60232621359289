import { NgModule } from '@angular/core';
import { CoreModule } from 'src/app/core/core.module';
import { HomepageComponent } from './homepage.component';
import { HomepageRoutingModule } from './homepage.routing';

@NgModule({
  declarations: [HomepageComponent],
  imports: [HomepageRoutingModule, CoreModule],
})
export class HomepageModule {}
