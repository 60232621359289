<section class="checkbox-section">
  <span class="checkbox-list-section">
    <mat-checkbox
      class="checkbox-margin"
      [checked]="allComplete"
      [color]="'primary'"
      [indeterminate]="
        someComplete() || (indeterminate && this.form?.value[0]?.length > 0)
      "
      (change)="setAll($event.checked)"
    >
      {{ carOptions.name }}
    </mat-checkbox>
  </span>

  <span class="checkbox-list-section">
    <ul>
      <li *ngFor="let option of carOptions.options">
        <mat-checkbox
          [(ngModel)]="option.completed"
          [color]="'primary'"
          (ngModelChange)="updateAllComplete()"
        >
          {{ option.name }}
        </mat-checkbox>
      </li>
    </ul>
  </span>
</section>
