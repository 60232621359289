<div class="col-12">
  <!-- Portfolio -->
  <section>
    <header class="major">
      <h2>{{ title }}</h2>
    </header>

    <ng-container *ngIf="cars.length > 0; else elseLoader">
      <mat-paginator
        #paginatorTop="matPaginator"
        *ngIf="dataSource.length > 6"
        [length]="dataSource.length"
        [pageSize]="pageSize"
        [hidePageSize]="true"
        (page)="onPageChanged($event)"
      >
      </mat-paginator>

      <autoclean-car-list-card
        *ngIf="activePageDataChunk.length > 0"
        [listOfCars]="activePageDataChunk"
      >
      </autoclean-car-list-card>

      <mat-paginator
        #paginatorBottom="matPaginator"
        *ngIf="dataSource.length > 6"
        [length]="dataSource.length"
        [pageSize]="pageSize"
        [hidePageSize]="true"
        (page)="onPageChanged($event)"
      >
      </mat-paginator>
    </ng-container>

    <ng-template #elseLoader>
      <autoclean-loader></autoclean-loader>
    </ng-template>
  </section>
</div>
